<template>
	<ion-page>
		<spot-header :title="stringPageTitle" :canGoHome="true" :canGoBack="false" @pressedGoHome="$router.push('/home')" @pressedGoBack="exitSelection"></spot-header>

		<ion-content :fullscreen="true"> <!--v-if="isActive"-->
			<ion-progress-bar type="indeterminate" v-if="!isActive"></ion-progress-bar>
			<div id="actionArea" v-else>
				<web-socket-reader crReplacedChar="|" @readedBarcode="onReadedBarcode" @readedTag="onReadedTag" @readerError="onReaderError"></web-socket-reader>
				<ion-item lines="none" class="no-pad">
					<spot-input
						:ref="fields[field.search].name"
						:label="fields[field.search].text"
						:allignLabel="fields[field.search].allignLabel"
						:required="fields[field.search].required"
						:placeholder="fields[field.search].placeholder"
						:enabled="true"
						:value="fields[field.search].value"
						:type="fields[field.search].inputType"
						:clearInput="true"
						@textChanged="changedSearch">
					</spot-input>
					<!--
					<ion-button size="default" slot="end" disabled="true" @click="createNewProject">
						<ion-icon :icon="duplicate" slot="start" size="default"></ion-icon>
						{{ stringNewElement }}
					</ion-button>
					-->
				</ion-item>
				<ion-progress-bar type="indeterminate" v-if="!tablesData.projects"></ion-progress-bar>
				<EasyDataTable
					:headers="headers"
					:items="tablesData.projects"
					:sort-by="sortBy"
					:sort-type="sortType"
					:search-field="searchFields"
					:search-value="fields[field.search].value"
					:empty-message="stringEmptyElement"
					multi-sort
					alternating
					show-index
				>
					<template #item-actions="project">
						<ion-button :color="project.closed || project.delivered ? 'danger' : project.partial ? 'warning' : 'success'" :disabled="project.closed || project.delivered" fill="outline" shape="round" size="small" slot="icon-only" @click="selectedExistingProject(project)">
							<ion-icon :icon="enter" slot="end" size="small"></ion-icon>
						</ion-button>
						<ion-button disabled=false fill="outline" shape="round" size="small" slot="icon-only" @click="showExistingProject(project)">
							<ion-icon :icon="reader" slot="end" size="small"></ion-icon>
						</ion-button>
						<!--
						<ion-button disabled=true fill="outline" shape="round" size="small" slot="icon-only" @click="editExistingProject(project)">
							<ion-icon :icon="brush" slot="end" size="small"></ion-icon>
						</ion-button>
						-->
						<ion-button disabled=true fill="outline" shape="round" size="small" slot="icon-only" @click="deleteExistingProject(project)">
							<ion-icon :icon="trash" slot="end" size="small"></ion-icon>
						</ion-button>
					</template>
				</EasyDataTable>
				<ion-modal ref="modal" backdrop-dismiss="false" :is-open="isOpenModal" @didDismiss="modalDismissed">
					<ion-content>
						<ion-toolbar>
							<ion-title> {{ modalTitle }}</ion-title>
						</ion-toolbar>
						<div v-for="field in orderContents" :key="field.id">
							<spot-input
								v-if="(field.inputType == 'text' || field.inputType == 'number')"
								:ref="field.name"
								:label="field.text"
								:required="field.required"
								:placeholder="getPlaceHolder(field)"
								:enabled="modalReadOnly == true ? false : (field.id >= 1 ? true : false)"
								:validationState="field.validationState"
								:value="field.value"
								:type="field.inputType"
								@textChanged="changedTextField(field.id, $event)">
							</spot-input>
							<spot-checkbox
								v-if="field.inputType == 'check'"
								:ref="field.name"
								:label="field.text"
								:required="field.required"
								:enabled="modalReadOnly == true ? false : (field.id >= 1 ? true : false)"
								:validationState="field.validationState"
								:value="field.value"
								@checkboxChanged="changedCheckboxField(field.id, $event)">
							</spot-checkbox>
						</div>
						<div class="flex-row-center-container">
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="undoText"
								@clicked="closeModal(false)">
							</spot-button>
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="!isEmptyRequiredFields && modalReadOnly == false"
								:expand="null"
								:text="confirmText"
								@clicked="closeModal(true)">
							</spot-button>
						</div>
					</ion-content>
				</ion-modal>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>Test Area</div>
				<spot-button
					fill="outline"
					size="large"
					color="primary"
					:enabled="true"
					text="Simulate Scan"
					@clicked="onReadedBarcode('1111111111111','barcode')">
				</spot-button>
				<div v-for="field in fields" :key="field.id">
					{{ field.name }} = {{ field.value }}
				</div>
			</div>
		</ion-content>

	</ion-page>
</template>

<style scoped>
	ion-modal {
		--height: 80%;
		--width: 90%;
		--border-radius: 16px;
		--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	}
	ion-modal::part(backdrop) {
		background: var(--ion-color-medium);
		opacity: 1;
	}
	ion-modal ion-toolbar {
		--background: var(--ion-color-primary);
		--color: white;
	}
	.flex-row-center-container {
		margin-top: 0.2em;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.no-pad {
		margin-top: 0px;
		margin-bottom: 0px;
		--padding-top: 0px;
		--padding-bottom: 0px;
		--inner-padding-top: 0px;
		--inner-padding-bottom: 0px;
		--min-height: 0px
	}
	*:deep(ion-checkbox) {
		margin-top: 0px;
		margin-bottom: 0px;
	}
</style>

<script>
import { IonButton, IonContent, IonIcon, IonItem, IonModal, IonPage, IonProgressBar, IonTitle, IonToolbar } from '@ionic/vue';
import { brush, duplicate, enter, reader, trash } from 'ionicons/icons';
import { defineComponent } from 'vue';
import SpotButton from '../components/SpotButton.vue';
import SpotCheckbox from '../components/SpotCheckbox.vue';
import SpotInput from '../components/SpotInput.vue';
import SpotHeader from "../components/SpotHeader.vue";
import WebSocketReader from '../components/WebSocketReader.vue';
import { /* checkUserSession, */ confirmYesNo /*, insertValue*/, settings, showMsgError, showMsgInfo, showMsgWarning, writeLog } from '../plugins/common.js';
import { createProject, deleteProject, searchProjectTypology, searchProjects, tablesData, updateProject } from '../plugins/customDatabase.js';

	export default defineComponent({
		name: "SelectOrder",
		props: {
			pageTitle: { type: String, default: 'Seleziona Ordine' },
			customerOrderText: { type: String, default: 'Ordine Cliente' }, //'Uscita Merce' / 'Ordine'
			supplierOrderText: { type: String, default: 'Ordine Fornitore' }, //'Ingresso Merce' / / 'Bolla' / 'Ordine'
			pickingListText: { type: String, default: 'Lista di Prelievo' }, //'Lista'
			customerOrderTitle: { type: String, default: 'placeholder.goods_issue_with_list' },
			supplierOrderTitle: { type: String, default: 'placeholder.goods_receipt_with_list' },
			pickingListTitle: { type: String, default: 'placeholder.picking_list' },
			undoText: { type: String, default: 'Annulla' },
			confirmText: { type: String, default: 'Conferma' },
			unselected: { type: String, default: 'Nessuno' },
			selectElement: { type: String, default: 'Seleziona ' },
			emptyElement: { type: String, default: ' non presente' },
			newElementM: { type: String, default: ' Nuovo ' },
			newElementF: { type: String, default: ' Nuova ' },
			changeElement: { type: String, default: 'Modifica ' },
			showElement: { type: String, default: 'Visualizza ' },
			searchElement: { type: String, default: 'Ricerca ' },
			notFoundElementM: { type: String, default: ' non trovato!' },
			notFoundElementF: { type: String, default: ' non trovata!' },
			deleteElement: { type: String, default: 'Cancella ' },
			confirmDeleteElement: { type: String, default: 'Confermi cancellazione ' },
			noOrdersText: { type: String, default: 'Nessun Ordine presente' },
			newOrderText: { type: String, default: 'Nuovo Ordine' },
			editOrderText: { type: String, default: 'Modifica Ordine'}, //'Modifica Ordine: ' / 'Modifica: '
			showOrderText: { type: String, default: 'Visualizza Ordine'}, //'Visualizza Ordine: ' / 'Modifica: '
			searchOrderText: { type: String, default: 'Ricerca Ordine' },
			messageSearchNotFound: { type: String, default: 'Ordine non trovato!' },
			confirmDeleteOrderText: { type: String, default: 'Conferma Cancellazione' },
			confirmMessageDelete: { type: String, default: 'Confermi la cancellazione dell\'ordine corrente?' },
			checkTagText: { type: String, default: 'Controllo TAG' },
			warnMultipleTAG: { type: String, default: 'Letto più di un TAG. Avvicinarsi solo al TAG da leggere e riprovare' },
			warnInavlidTAG: { type: String, default: 'TAG letto solo in parte. Riprovare a leggere' },
			messageTitleReader: { type: String, default: 'Lettura Barcode/TAG' },
			errorReadingReader: { type: String, default: 'Errore nella lettura del barcode/tag' },
		},
		components: {
			IonPage,
			IonContent,
			IonProgressBar,
			IonItem,
			IonButton,
			IonIcon,
			IonModal,
			IonToolbar,
			IonTitle,
			SpotHeader,
			SpotInput,
			SpotCheckbox,
			SpotButton,
			WebSocketReader,
		},
		data: () => ({
			isActive: null,
			field: {
				search: 0,
			},
			fields: [
				{ id: 0, name: 'search', text: 'Cerca: ', allignLabel: true, placeholder: 'Cerca Ordine', inputType: 'text', defaultValue: '', value: null, required: false, refField: null },
			],
			searchFields: ['name', 'description'],
			headers: [
				{ text: 'Ordine', value: 'name', sortable: true },
				{ text: 'Azioni', value: 'actions', sortable: false }
			],
			sortType: ['asc', 'asc', 'asc', 'asc'],
			sortBy: ['closed', 'delivered', 'partial', 'name'],
			orderContent: {
				id: 0,
				name: 1,
				description: 2,
				customer: 3,
				supplier: 4,
				closed: 5,
				partial: 6,
				delivered: 7,
			},
			orderContents: [
				{ id: 0, name: 'id', text: 'Id', placeholder: 'Inserisci ID', inputType: "number", validationState: null, defaultValue: null, value: null, required: true },
				{ id: 1, name: 'name', text: 'Ordine', placeholder: 'Inserisci Ordine', inputType: "text", validationState: null, defaultValue: null, value: null, required: true },
				{ id: 2, name: 'description', text: 'Descrizione', placeholder: 'Inserisci Descrizione', inputType: "text", validationState: null, defaultValue: null, value: null, required: true },
				{ id: 3, name: 'customer', text: 'Cliente', placeholder: 'Inserisci Cliente', inputType: "text", validationState: null, defaultValue: null, value: null, required: false },
				{ id: 4, name: 'supplier', text: 'Fornitore', placeholder: 'Inserisci Fornitore', inputType: "text", validationState: null, defaultValue: null, value: null, required: false },
				{ id: 5, name: 'closed', text: 'Chiuso', inputType: "check", validationState: null, defaultValue: false, value: null, required: false },
				{ id: 6, name: 'partial', text: 'Parziale', inputType: "check", validationState: null, defaultValue: false, value: null, required: false },
				{ id: 7, name: 'delivered', text: 'Spedito', inputType: "check", validationState: null, defaultValue: false, value: null, required: false },
			],
			isOpenModal: false,
			modalTitle: null,
			modalReadOnly: null,
			actionDbCreate: null,
			isIncoming: null,
			isOutgoing: null,
			isPicking: null,
			lastBarcode: null,
			lastTag: null,
			duplicate,
			enter,
			reader,
			trash,
			brush,
			settings,
			confirmYesNo,
			showMsgError,
			showMsgInfo,
			showMsgWarning,
			writeLog,
			searchProjectTypology,
			searchProjects,
			createProject,
			updateProject,
			deleteProject,
			tablesData,
		}),
		async ionViewWillEnter() {
			this.getUrlParamsAndQuery();
			this.setDefault();
			await this.getAllTablesData();
			this.isActive = true;
		},
		ionViewWillLeave() {
			this.isOpenModal = false;
			this.isActive=false;
		},
		computed: {
			isEmptyRequiredFields() {
				let ret = false;
				this.orderContents.forEach(element => {if (element.required && !element.value && element.id > 0) ret=true});
				return ret;
			},
			stringPageTitle() {
				if (this.isIncoming) return this.selectElement + this.supplierOrderText
				else if (this.isOutgoing) return this.selectElement + this.customerOrderText
				else if (this.isPicking) return this.selectElement + this.pickingListText
				else return this.pageTitle;
			},
			stringEmptyElement() {
				if (this.isIncoming) return this.supplierOrderText + this.emptyElement
				else if (this.isOutgoing) return this.customerOrderText + this.emptyElement
				else if (this.isPicking) return this.pickingListText + this.emptyElement
				else return this.noOrdersText;
			},
			stringNewElement() {
				if (this.isIncoming) return this.newElementM + this.supplierOrderText
				else if (this.isOutgoing) return this.newElementM + this.customerOrderText
				else if (this.isPicking) return this.newElementF + this.pickingListText
				else return this.newOrderText;
			},
			stringChangeElement() {
				if (this.isIncoming) return this.changeElement + this.supplierOrderText
				else if (this.isOutgoing) return this.changeElement + this.customerOrderText
				else if (this.isPicking) return this.changeElement + this.pickingListText
				else return this.editOrderText;
			},
			stringShowElement() {
				if (this.isIncoming) return this.showElement + this.supplierOrderText
				else if (this.isOutgoing) return this.showElement + this.customerOrderText
				else if (this.isPicking) return this.showElement + this.pickingListText
				else return this.showOrderText;
			},
			stringSearchElement() {
				if (this.isIncoming) return this.searchElement + this.supplierOrderText
				else if (this.isOutgoing) return this.searchElement + this.customerOrderText
				else if (this.isPicking) return this.searchElement + this.pickingListText
				else return this.searchOrderText;
			},
			stringNotFoundElement() {
				if (this.isIncoming) return this.supplierOrderText + this.notFoundElementM
				else if (this.isOutgoing) return this.customerOrderText + this.notFoundElementM
				else if (this.isPicking) return this.pickingListText + this.notFoundElementF
				else return this.messageSearchNotFound;
			},
			stringDeleteElement() {
				if (this.isIncoming) return this.deleteElement + this.supplierOrderText
				else if (this.isOutgoing) return this.deleteElement + this.customerOrderText
				else if (this.isPicking) return this.deleteElement + this.pickingListText
				else return this.confirmDeleteOrderText;
			},
			stringConfirmDeleteElement() {
				if (this.isIncoming) return this.confirmDeleteElement + this.supplierOrderText
				else if (this.isOutgoing) return this.confirmDeleteElement + this.customerOrderText
				else if (this.isPicking) return this.confirmDeleteElement + this.pickingListText
				else return this.confirmMessageDelete;
			},
		},
		methods: {
			// Settings & StartUp
			getUrlParamsAndQuery() {
				this.isIncoming = (/true/i).test(this.$route.query.incoming);
				this.isOutgoing = (/true/i).test(this.$route.query.outgoing);
				this.isPicking = (/true/i).test(this.$route.query.picking);
				this.writeLog(this.isIncoming, this.isOutgoing, this.isPicking)
			},
			setDefault() {
				this.fields.forEach(element => element.value = element.defaultValue);
				this.isOpenModal = false;
				this.modalTitle = null;
				this.modalReadOnly = null;
				this.actionDbCreate = null;
				this.lastBarcode = null;
				this.lastTag = null;
			},
			getPlaceHolder(element) {
				return ((this.settings.showFieldsPlaceholder) ? (element.required ? element.placeholder : this.unselected ) : '')
			},
			// Database
			async getAllTablesData() {
				this.tablesData.projects = [];
				this.tablesData.currentProjectTypology = null;
				if (this.isIncoming == true) await this.searchProjectTypology(this.supplierOrderTitle);
				if (this.isOutgoing == true) await this.searchProjectTypology(this.customerOrderTitle);
				if (this.isPicking == true) await this.searchProjectTypology(this.pickingListTitle);
				await this.searchProjects(this.tablesData.currentProjectTypology.id);
			},
			async deleteExistingProject(project) {
				let canDelete = false;
				await this.confirmYesNo(this.stringDeleteElement, this.stringConfirmDeleteElement)
					.then((data) => { canDelete = data })
				if (canDelete == true) {
					await this.deleteProject(project.id)
				}
			},
			// Actions
			async onReadedBarcode(barcode,type) {
				this.lastBarcode = barcode;
				this.writeLog(barcode, type);
				await this.committedBarcode();
			},
			async onReadedTag(tag,type) {
				this.lastTag = tag;
				this.writeLog(tag, type);
				await this.committedTag();
			},
			onReaderError(data,error) {
				this.showMsgError(data, error);
				this.showMsgWarning(this.messageTitleReader, this.errorReadingReader);
			},
			changedSearch(value) {
				this.fields[this.field.search].value = value;
			},
			changedTextField(fieldId, value) {
				this.orderContents[fieldId].value = value;
				(!value || value.length == 0)? this.orderContents[fieldId].validationState = false : this.orderContents[fieldId].validationState = true
			},
			changedCheckboxField(fieldId, value) {
				this.orderContents[fieldId].value = value;
				(!value || value.length == 0)? this.orderContents[fieldId].validationState = false : this.orderContents[fieldId].validationState = true
			},
			prepareNewRecord() {
				let myRecord = {
					project: {
						name: this.orderContents[this.orderContent.name].value,
						description: this.orderContents[this.orderContent.description].value,
						customer: this.orderContents[this.orderContent.customer].value,
						supplier: this.orderContents[this.orderContent.supplier].value,
						closed: this.orderContents[this.orderContent.closed].value,
						partial: this.orderContents[this.orderContent.partial].value,
						delivered: this.orderContents[this.orderContent.delivered].value,
					}
				};
				return myRecord;
			},
			prepareUpdateProject() {
				let myRecord = {
					project: {
						name: this.orderContents[this.orderContent.name].value,
						description: this.orderContents[this.orderContent.description].value,
					}
				};
				return myRecord;
			},
			updateOrderContents(row) {
				Object.getOwnPropertyNames(row).forEach(rowField => {
					this.orderContents.forEach(pageElement => {
						if (rowField == pageElement.name) {
							pageElement.value = row[rowField];
						}
					});
				});
			},
			updateCustomOrderContents(row) {
				this.orderContents[this.orderContent.id].value = row.id
				this.orderContents[this.orderContent.name].value = row.name
				this.orderContents[this.orderContent.description].value = row.description
				this.orderContents[this.orderContent.customer].value = (!row.customer ? null : row.customer.name)
				this.orderContents[this.orderContent.supplier].value = (!row.supplier ? null : row.supplier.name)
				this.orderContents[this.orderContent.closed].value = row.closed
				this.orderContents[this.orderContent.partial].value = row.partial
				this.orderContents[this.orderContent.delivered].value = row.delivered
			},
			createNewProject() {
				this.orderContents.forEach(element => element.value = element.defaultValue);
				this.orderContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.stringNewElement;
				this.actionDbCreate = true;
				this.openModal(false);
			},
			async editExistingProject(project) {
				this.updateCustomOrderContents(project);
				this.orderContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.stringChangeElement // + project.name;
				this.actionDbCreate = false;
				this.openModal(false);
			},
			async showExistingProject(project) {
				this.updateCustomOrderContents(project);
				this.orderContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.stringShowElement // + project.name;
				this.actionDbCreate = false;
				this.openModal(true);
			},
			async selectedExistingProject(project) {
				if (!this.isOpenModal) {
					this.$router.push("/order/management/" + project.id + "?incoming=" + this.isIncoming + "&outgoing=" + this.isOutgoing + "&picking=" + this.isPicking)
				}
			},
			openModal(isReadOnly) {
				this.modalReadOnly = isReadOnly;
				this.isOpenModal = true;
			},
			async closeModal(saveRecord) {
				if (saveRecord == true) {
					if (this.actionDbCreate == true) {
						await this.createProject(this.prepareNewRecord())
					}
					else {
						await this.updateProject(this.orderContents[this.orderContent.id].value, this.prepareUpdateProject())
					}
					await this.getAllTablesData();
				}
				this.isOpenModal = false;
			},
			async modalDismissed() {
				this.actionDbCreate == null;
			},
			changedBarcode(value) {
				this.lastBarcode = value;
			},
			async committedBarcode() {
				let foundElement = null;
				this.tablesData.projects.some(project => {
					if (project.name == this.lastBarcode) {
						foundElement = project;
						return true;
					}
				});
				if (!foundElement) this.showMsgWarning(this.stringSearchElement, this.stringNotFoundElement);
				else this.selectedExistingProject(foundElement);
			},
			changedTag(value) {
				this.lastTag = value;
			},
			async committedTag() {
				let tagElements = this.lastTag.split('|');
				let foundElement = null;

				switch (tagElements.length) {
					case 0:
						this.lastTag = null;
						this.showMsgWarning(this.checkTagText,this.warnInavlidTAG);
						break;
					case 1:
						this.tablesData.projects.some(project => {
							if (project.name == this.lastTag) {
								foundElement = project;
								return true;
							}
						});
						if (!foundElement) this.showMsgWarning(this.stringSearchElement, this.stringNotFoundElement);
						else this.selectedExistingProject(foundElement);
						break;
					default: // in futuro si può implementare la gestione di tag multipli per selezionare più ordini
						this.lastTag = null;
						this.showMsgWarning(this.checkTagText,this.warnMultipleTAG);
						break;
				}
			},
			async exitSelection() {
				let canExit = !this.isOpenModal;
				
				if (canExit == true) {
					this.$router.push("/home");
				}
			}
		}
	});
</script>