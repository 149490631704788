<template>
 <ion-page>
		<spot-header :title="pageTitle + ' ' + (!tablesData || !tablesData.currentProject ? '' : tablesData.currentProject.name)" :canGoHome="false" :canGoBack="true" @pressedGoHome="$router.push('/home')" @pressedGoBack="exitOrder"></spot-header>

		<ion-content :fullscreen="true"> <!--v-if="isActive"-->
			<ion-progress-bar type="indeterminate" v-if="!isActive"></ion-progress-bar>
			<div id="actionArea" v-else>
				<web-socket-reader crReplacedChar="|" @readedBarcode="onReadedBarcode" @readedTag="onReadedTag" @readerError="onReaderError"></web-socket-reader>
				<ion-item>
					<spot-input
						:ref="fields[field.search].name"
						:label="fields[field.search].text"
						:allignLabel="fields[field.search].allignLabel"
						:required="fields[field.search].required"
						:placeholder="fields[field.search].placeholder"
						:enabled="true"
						:value="fields[field.search].value"
						:type="fields[field.search].inputType"
						:clearInput="true"
						@textChanged="changedSearch">
					</spot-input>
					<!--
					<ion-button size="default" slot="end" disabled="true" @click="createNewPick">
						<ion-icon :icon="duplicate" slot="start" size="default"></ion-icon>
						{{ newItemText }}
					</ion-button>
					-->
				</ion-item>
				<ion-progress-bar type="indeterminate" v-if="!tablesData.picks"></ion-progress-bar> <!-- v-if="!tablesData.projectRows" -->
				<EasyDataTable
					:headers="headers"
					:items="tablesData.picks"
					:sort-by="sortBy"
					:sort-type="sortType"
					:search-field="searchFields"
					:search-value="fields[field.search].value"
					:empty-message="noItemsText"
					multi-sort
					alternating> <!-- :items="tablesData.projectRows" show-index -->
					<template #item-actions="pick"> <!-- #item-actions="projectRow" -->
						<!--
						<ion-button disabled=false fill="outline" shape="round" size="small" slot="icon-only" @click="showExistingPick(pick)">
							<ion-icon :icon="reader" slot="end" size="small"></ion-icon>
						</ion-button>
						-->
						<ion-button :color="pick.quantity_detected == pick.quantity ? 'success' : (pick.quantity_detected == 0 ? 'danger' : (pick.quantity_detected < pick.quantity ? 'warning' : 'primary'))" disabled=false fill="outline" shape="round" size="small" slot="icon-only" @click="editExistingPick(pick)">
							<ion-icon :icon="brush" slot="end" size="small"></ion-icon>
						</ion-button>
						<!--
						<ion-button disabled=true fill="outline" shape="round" size="small" slot="icon-only" @click="deleteExistingPick(pick)">
							<ion-icon :icon="trash" slot="end" size="small"></ion-icon>
						</ion-button>
						-->
					</template>
				</EasyDataTable>
				<ion-modal ref="modal" backdrop-dismiss="false" :is-open="isOpenModal" @didDismiss="modalDismissed">
					<ion-content>
						<ion-toolbar>
							<ion-title> {{ modalTitle }}</ion-title>
						</ion-toolbar>
						<div v-for="field in itemContents" :key="field.id">
							<spot-input
								v-if="(field.inputType == 'text' || field.inputType == 'number')"
								:ref="field.name"
								:label="field.text"
								:required="field.required"
								:placeholder="getPlaceHolder(field)"
								:enabled="modalReadOnly == true ? false : (field.id == 5 ? true : false)"
								:validationState="field.validationState"
								:value="field.value"
								:type="field.inputType"
								@textChanged="changedTextField(field.id, $event)">
							</spot-input>
							<spot-checkbox
								v-if="field.inputType == 'check'"
								:ref="field.name"
								:label="field.text"
								:required="field.required"
								:enabled="modalReadOnly == true ? false : (field.id == 5 ? true : false)"
								:validationState="field.validationState"
								:value="field.value"
								@checkboxChanged="changedCheckboxField(field.id, $event)"
								@focus="$event.target.select()">
							</spot-checkbox>
						</div>
						<div class="flex-row-center-container">
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="undoText"
								@clicked="closeModal(false)">
							</spot-button>
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="!isEmptyRequiredFields && modalReadOnly == false"
								:expand="null"
								:text="confirmText"
								@clicked="closeModal(true)">
							</spot-button>
						</div>
					</ion-content>
				</ion-modal>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>Test Area</div>
				<spot-button
					fill="outline"
					size="large"
					color="primary"
					:enabled="true"
					text="Simulate Scan"
					@clicked="onReadedBarcode('1111111111111','barcode')">
				</spot-button>
				<div v-for="field in fields" :key="field.id">
					{{ field.name }} = {{ field.value }}
				</div>
			</div>
		</ion-content>

 </ion-page>
</template>

<style scoped>
	ion-modal {
		--height: 80%;
		--width: 90%;
		--border-radius: 16px;
		--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	}
	ion-modal::part(backdrop) {
		background: var(--ion-color-medium);
		opacity: 1;
	}
	ion-modal ion-toolbar {
		--background: var(--ion-color-primary);
		--color: white;
	}
	.flex-row-center-container {
		margin-top: 0.2em;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.no-pad {
		margin-top: 0px;
		margin-bottom: 0px;
		--padding-top: 0px;
		--padding-bottom: 0px;
		--inner-padding-top: 0px;
		--inner-padding-bottom: 0px;
		--min-height: 0px
	}
	*:deep(ion-checkbox) {
		margin-top: 0px;
		margin-bottom: 0px;
	}
</style>

<script>
import { IonButton, IonContent, IonIcon, IonItem, IonModal, IonPage, IonProgressBar, IonTitle, IonToolbar } from '@ionic/vue';
import { brush, duplicate, trash } from 'ionicons/icons';
import { defineComponent } from 'vue';
import SpotButton from '../components/SpotButton.vue';
import SpotCheckbox from '../components/SpotCheckbox.vue';
import SpotInput from '../components/SpotInput.vue';
import SpotHeader from "../components/SpotHeader.vue";
import WebSocketReader from '../components/WebSocketReader.vue';
import { confirmYesNo, settings, showMsgError, showMsgInfo, showMsgWarning, writeLog } from '../plugins/common.js';
import { createProjectRow, deleteProjectRow, deletePick, getProject, searchPicks, searchProjectRows, tablesData, updateProjectRow, updateProject } from '../plugins/customDatabase.js';

	export default defineComponent({
		name: "ManageOrder",
		props: {
			pageTitle: { type: String, default: 'Gestisci Ordine' },
			noItemsText: { type: String, default: 'Nessun Articolo presente' },
			newItemText: { type: String, default: 'Nuovo Articolo' },
			editItemText: { type: String, default: 'Modifica Articolo'}, //'Modifica Articolo: '
			showItemText: { type: String, default: 'Visualizza Articolo'}, //'Visualizza Articolo: '
			undoText: { type: String, default: 'Annulla' },
			confirmText: { type: String, default: 'Conferma' },
			unselected: { type: String, default: 'Nessuno' },
			messageSearchItemTitle: { type: String, default: 'Ricerca Articolo' },
			messageSearchItemNotFound: { type: String, default: 'Articolo non trovato!' },
			messageTitleCheckTAG: { type: String, default: 'Controllo TAG' },
			warnMultipleTAG: { type: String, default: 'Letto più di un TAG. Avvicinarsi solo al TAG da leggere e riprovare' },
			warnInavlidTAG: { type: String, default: 'TAG letto solo in parte. Riprovare a leggere' },
			confirmDeleteTitle: { type: String, default: 'Conferma Cancellazione' },
			confirmDeleteMessage: { type: String, default: 'Confermi la cancellazione dell\'articolo corrente?' },
			messageTitleReader: { type: String, default: 'Lettura Barcode/TAG' },
			errorReadingReader: { type: String, default: 'Errore nella lettura del barcode/tag' },
			confirmExitTitle: { type: String, default: 'Conferma Uscita' },
			confirmExitMessage: { type: String, default: 'Confermi l\'uscita dalla rilevazione anche se non sono stati inseriti tutti i dati?' },
		},
		components: {
			IonPage,
			IonContent,
			IonProgressBar,
			IonItem,
			IonButton,
			IonIcon,
			IonModal,
			IonToolbar,
			IonTitle,
			SpotHeader,
			SpotInput,
			SpotCheckbox,
			SpotButton,
			WebSocketReader,
		},
		data: () => ({
			isActive: null,
			field: {
				search: 0,
			},
			fields: [
				{ id: 0, name: 'search', text: 'Cerca: ', allignLabel: true, placeholder: 'Cerca Articolo', inputType: 'text', defaultValue: '', value: null, required: false, refField: null },
			],
			searchFields: ['item.code','item.description'],
			headers: [
				// { text: 'Ubicazione', value: 'location.name', sortable: true },
				{ text: 'Articolo', value: 'item.code', sortable: true },
				{ text: 'Quantità', value: 'quantity_detected', sortable: false },
				{ text: 'Azioni', value: 'actions', sortable: false }
			],
			sortType: ['asc', 'asc'],
			sortBy: ['location.name','item.code'],
			itemContent: {
				id: 0,
				location: 1,
				code: 2,
				description: 3,
				expectedQuantity: 4,
				quantity: 5,
			},
			itemContents: [
				{ id: 0, name: 'id', text: 'Id', placeholder: 'Inserisci ID', inputType: "number", validationState: null, defaultValue: null, value: null, required: true },
				{ id: 1, name: 'location', text: 'Ubicazione', placeholder: 'Inserisci Ubicazione', inputType: "text", validationState: null, defaultValue: null, value: null, required: true },
				{ id: 2, name: 'code', text: 'Articolo', placeholder: 'Inserisci Articolo', inputType: "text", validationState: null, defaultValue: null, value: null, required: true },
				{ id: 3, name: 'description', text: 'Descrizione', placeholder: 'Inserisci Descrizione', inputType: "text", validationState: null, defaultValue: null, value: null, required: true },
				{ id: 4, name: 'expectedQuantity', text: 'Quantità Prevista', placeholder: 'Inserisci Quantità Prevista', inputType: "number", validationState: null, defaultValue: null, value: null, required: true },
				{ id: 5, name: 'quantity', text: 'Quantità Rilevata', placeholder: 'Inserisci Quantità Rilevata', inputType: "number", validationState: null, defaultValue: null, value: null, required: true },
			],
			isOpenModal: false,
			modalTitle: null,
			modalReadOnly: null,
			actionDbCreate: null,
			isIncoming: null,
			isOutgoing: null,
			isPicking: null,
			projectId: null,
			lastBarcode: null,
			lastTag: null,
			duplicate,
			trash,
			brush,
			settings,
			confirmYesNo,
			showMsgError,
			showMsgInfo,
			showMsgWarning,
			writeLog,
			getProject,
			searchPicks,
			searchProjectRows,
			createProjectRow,
			updateProjectRow,
			deletePick,
			deleteProjectRow,
			updateProject,
			tablesData,
		}),
		async ionViewWillEnter() {
			this.getUrlParamsAndQuery();
			this.setDefault();
			await this.getAllTablesData();
			this.isActive = true;
		},
		ionViewWillLeave() {
			this.isOpenModal = false;
			this.isActive=false;
		},
		computed: {
			isEmptyRequiredFields() {
				let ret = false;
				this.itemContents.forEach(element => {if (element.required && !element.value && element.id > 0) ret=true});
				return ret;
			},
			isOrderClean() {
				let ret = true;
				this.tablesData.picks.forEach(element => {if (element.quantity_detected > 0) ret=false});
				return ret;
			},
			isOrderFinished() {
				let ret = true;
				this.tablesData.picks.forEach(element => {if (element.quantity != element.quantity_detected) ret=false});
				return ret;
			},
		},
		methods: {
			// Settings & StartUp
			getUrlParamsAndQuery() {
				this.isIncoming = (this.$route.query.incoming == 'true');
				this.isOutgoing = (this.$route.query.outgoing == 'true');
				this.isPicking = (this.$route.query.picking == 'true');
				this.projectId = this.$route.params.id;
			},
			setDefault() {
				this.fields.forEach(element => element.value = element.defaultValue);
				this.isOpenModal = false;
				this.modalTitle = null;
				this.modalReadOnly = null;
				this.actionDbCreate = null;
				this.lastBarcode = null;
				this.lastTag = null;
			},
			getPlaceHolder(element) {
				return ((this.settings.showFieldsPlaceholder) ? (element.required ? element.placeholder : this.unselected ) : '')
			},
			// Database
			async getAllTablesData() {
				await this.getProject(this.projectId);
				// this.tablesData.projectRows = [];
				this.tablesData.picks = [];
				// await this.searchProjectRows(this.projectId);
				await this.searchPicks(this.projectId);
			},
			async deleteExistingPick(pick) {
				let canDelete = false;
				await this.confirmYesNo(this.confirmDeleteTitle, this.confirmDeleteMessage)
					.then((data) => { canDelete = data })
				if (canDelete == true) {
					await this.deletePick(pick.id)
				}
			},
			async deleteExistingProjectRow(projectRow) {
				let canDelete = false;
				await this.confirmYesNo(this.confirmDeleteTitle, this.confirmDeleteMessage)
					.then((data) => { canDelete = data })
				if (canDelete == true) {
					await this.deleteProjectRow(projectRow.id)
				}
			},
			// Actions
			async onReadedBarcode(barcode,type) {
				this.lastBarcode = barcode;
				this.writeLog(barcode, type);
				await this.committedBarcode();
			},
			async onReadedTag(tag,type) {
				this.lastTag = tag;
				this.writeLog(tag, type);
				await this.committedTag();
			},
			onReaderError(data,error) {
				this.showMsgError(data, error);
				this.showMsgWarning(this.messageTitleReader, this.errorReadingReader);
			},
			changedSearch(value) {
				this.fields[this.field.search].value = value;
			},
			changedTextField(fieldId, value) {
				this.itemContents[fieldId].value = value;
				(!value || value.length == 0)? this.itemContents[fieldId].validationState = false : this.itemContents[fieldId].validationState = true
			},
			changedCheckboxField(fieldId, value) {
				this.itemContents[fieldId].value = value;
				(!value || value.length == 0)? this.itemContents[fieldId].validationState = false : this.itemContents[fieldId].validationState = true
			},
			prepareNewRecord() {
				let myRecord = {
					project_rows: {
						code: this.itemContents[this.itemContent.code].value,
						description: this.itemContents[this.itemContent.description].value,
						details: this.itemContents[this.itemContent.details].value,
						packages: this.itemContents[this.itemContent.packages].value,
						barcode: this.itemContents[this.itemContent.barcode].value,
						weight: this.itemContents[this.itemContent.weight].value,
						quantity: this.itemContents[this.itemContent.quantity].value,
						expiration: this.itemContents[this.itemContent.expiration].value,
						month_end: this.itemContents[this.itemContent.month_end].value,
						durability: this.itemContents[this.itemContent.durability].value,
						location_id: 1,
						measure_unit_id: 3,
					}
				};
				return myRecord;
			},
			prepareUpdateRecord() {
				let myRecord = {
					project_rows: {
						quantity: this.itemContents[this.itemContent.quantity].value,
					}
				};
				return myRecord;
			},
			updateItemContents(row) {
				Object.getOwnPropertyNames(row).forEach(rowField => {
					this.itemContents.forEach(pageElement => {
						if (rowField == pageElement.name) {
							pageElement.value = row[rowField];
						}
					});
				});
			},
			updateCustomItemContents(row) {
				this.itemContents[this.itemContent.id].value = row.project_row.id
				this.itemContents[this.itemContent.location].value = row.location.name
				this.itemContents[this.itemContent.code].value = row.item.code
				this.itemContents[this.itemContent.description].value = row.item.description
				this.itemContents[this.itemContent.expectedQuantity].value = parseInt(row.quantity)
				this.itemContents[this.itemContent.quantity].value = parseInt(row.quantity_detected)
			},
			createNewPick() {
				this.itemContents.forEach(element => element.value = element.defaultValue);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.newItemText;
				this.actionDbCreate = true;
				this.openModal(false);
			},
			createNewProjectRow() {
				this.itemContents.forEach(element => element.value = element.defaultValue);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.newItemText;
				this.actionDbCreate = true;
				this.openModal(false);
			},
			async editExistingPick(pick) {
				this.updateCustomItemContents(pick);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.editItemText // + pick.item.code;
				this.actionDbCreate = false;
				this.openModal(false);
			},
			async editExistingProjectRow(projectRow) {
				this.updateCustomItemContents(projectRow);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.editItemText // + projectRow.item.code;
				this.actionDbCreate = false;
				this.openModal(false);
			},
			async showExistingPick(pick) {
				this.updateCustomItemContents(pick);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.showItemText // + pick.item.code;
				this.actionDbCreate = false;
				this.openModal(true);
			},
			async showExistingProjectRow(projectRow) {
				this.updateCustomItemContents(projectRow);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.showItemText // + projectRow.item.code;
				this.actionDbCreate = false;
				this.openModal(true);
			},
			openModal(isReadOnly) {
				this.modalReadOnly = isReadOnly;
				this.isOpenModal = true;
			},
			async closeModal(saveRecord) {
				if (saveRecord == true) {
					if (this.actionDbCreate == true) {
						await this.createProjectRow(this.prepareNewRecord())
					}
					else {
						await this.updateProjectRow(this.itemContents[this.itemContent.id].value, this.prepareUpdateRecord())
					}
					await this.getAllTablesData();
				}
				this.isOpenModal = false;
			},
			async modalDismissed() {
				this.actionDbCreate == null;
			},
			changedBarcode(value) {
				this.lastBarcode = value;
			},
			async committedBarcode() {
				let foundElement = null;
				this.tablesData.picks.some(pick => {
					if (pick.item.code == this.lastBarcode) {
						foundElement = pick;
						return true;
					}
				});
				if (!foundElement) this.showMsgWarning(this.messageSearchItemTitle, this.messageSearchItemNotFound);
				else this.editExistingPick(foundElement);
			},
			changedTag(value) {
				this.lastTag = value;
			},
			async committedTag() {
				let tagElements = this.lastTag.split('|');
				let foundElement = null;

				switch (tagElements.length) {
					case 0:
						this.lastTag = null;
						this.showMsgWarning(this.messageTitleCheckTAG,this.warnInavlidTAG);
						break;
					case 1:
						this.tablesData.picks.some(pick => {
							if (pick.item.code == this.lastTag) {
								foundElement = pick;
								return true;
							}
						});
						if (!foundElement) this.showMsgWarning(this.messageSearchItemTitle, this.messageSearchItemNotFound);
						else this.editExistingPick(foundElement);
						break;
					default:
						this.lastTag = null;
						this.showMsgWarning(this.messageTitleCheckTAG,this.warnMultipleTAG);
						break;
				}
			},
			async exitOrder() {
				let canExit = true;
				let myRecord = {
					project: {}
				};
				
				if (this.isOrderFinished == true) {
					if (this.isIncoming == true) myRecord.project['closed'] = true;
					await this.updateProject(this.projectId, myRecord);
				}
				else {
					if (this.isOrderClean != true) {
						myRecord.project['partial'] = true;
						await this.updateProject(this.projectId, myRecord);
						await this.confirmYesNo(this.confirmExitTitle, this.confirmExitMessage)
						.then((data) => { canExit = data })
					} else {
						myRecord.project['closed'] = false;
						myRecord.project['partial'] = false;
						await this.updateProject(this.projectId, myRecord);
					}
				}

				if (canExit == true) {
					this.$router.push("/order/selection" + "?incoming=" + this.isIncoming + "&outgoing=" + this.isOutgoing + "&picking=" + this.isPicking);
				}
			}
		}
	});
</script>
